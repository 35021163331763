<template>
  <b-row align-h="center" v-if="dataLoading">
    <b-spinner
      class="mt-4"
      style="width: 4rem; height: 4rem"
      type="grow"
      variant="primary"
      label="Spinning"
    ></b-spinner>
  </b-row>
  <div v-else>
    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Sessions</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-baseline">
          <b-col md="10">
            <b-form-group
              label="Session"
              invalid-feedback="Session is required."
              ref="sess_name"
            >
              <b-form-input
                placeholder="Enter session name"
                ref="sess_name"
                v-model="sessionObj.session"
                @focusout="CheckSess()"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon"
              @click="AddSess()"
              :disabled="savingSess"
            >
              <b-spinner v-if="savingSess" small type="grow" />
              <feather-icon v-else :icon="buttonIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          :tbody-tr-class="rowClass"
          show-empty
          :items="sessions"
          :fields="sessFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(session)="data">
            <b-badge
              variant="light-primary"
              v-if="
                $store.state.currentBranch.organizationType == 'coaching' &&
                data.item.isCurrentSession
              "
            >
              {{ data.item.session }}
            </b-badge>
            <span v-else>{{ data.item.session }}</span>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editSess(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="DeleteSess(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              <b-button
                v-if="
                  $store.state.currentBranch.organizationType == 'coaching' &&
                  !data.item.isCurrentSession
                "
                variant="outline-primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="setCurrentSess(data.item.id)"
              >
                <feather-icon icon="CheckCircleIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <!-- <h3 class="mb-1">Question</h3> -->
    <b-card>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Class"
            invalid-feedback="Class is required."
            ref="class"
          >
            <v-select
              v-model="myObj.clsID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="classes"
              :clearable="false"
              label="name"
              :reduce="(val) => val.id"
              input-id="id"
              ref="class"
              :disabled="dataLoading"
              placeholder="Select class"
              @input="FillSections()"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Section"
            invalid-feedback="section is required."
            ref="sec"
          >
            <!-- multiple
          :closeOnSelect="false" -->
            <v-select
              ref="sec"
              v-model="myObj.secID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currentSections"
              :reduce="(val) => val.id"
              label="section"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select section"
            />
            <!-- @input="setSec()" -->
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label="Exam Type"
            invalid-feedback="Exam type is required."
            ref="examType"
          >
            <v-select
              ref="examType"
              v-model="myObj.examID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="examType"
              :reduce="(val) => val.id"
              label="examType"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select exam type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group invalid-feedback="Session is required." ref="session">
            <div class="d-flex justify-content-between">
              <label class="bv-no-focus-ring col-form-label pt-0"
                >Session</label
              >
              <feather-icon
                class="cursor-pointer"
                icon="SettingsIcon"
                size="18"
                v-b-tooltip.hover.top
                title="Sessions"
                @click="openSession()"
              />
            </div>
            <v-select
              ref="session"
              v-model="myObj.sessionID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sessions"
              :reduce="(val) => val.id"
              label="session"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select session"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- <h3 class="mb-1">Options</h3> -->
    <b-card>
      <b-row
        v-for="(item, ind) in syllObj"
        :key="ind"
        class="mb-md-0 mb-1"
        style=""
      >
        <b-col xl="3" lg="3" md="3" class="d-flex">
          <b-form-group
            label="Date"
            invalid-feedback="Date is required"
            ref="date"
            class="mr-50"
          >
            <flat-pickr
              :config="config"
              v-model="item.date"
              class="form-control"
              placeholder="Select date"
              @on-change="setDate(item)"
            />
          </b-form-group>
          <b-form-group
            label="Day"
            invalid-feedback="Day is required"
            ref="day"
          >
            <b-form-input disabled placeholder="" v-model="item.day" />
          </b-form-group>
        </b-col>
        <!-- <b-col xl="2" lg="2" md="2" class="pl-0">
          <b-form-group
            label="Day"
            invalid-feedback="Day is required"
            ref="day"
          >
            <b-form-input disabled placeholder="" v-model="item.day" />
          </b-form-group>
        </b-col> -->
        <b-col xl="2" lg="2" md="2">
          <b-form-group
            label="Subject"
            invalid-feedback="Subject is required"
            ref="syll_sub"
          >
            <v-select
              ref="syll_sub"
              v-model="item.subjectID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="subjects"
              :clearable="false"
              label="subject"
              :reduce="(val) => val.id"
              :selectable="(opt) => !currIDs.includes(opt.id)"
              placeholder="Select subject"
              @input="setSubjects()"
            />
          </b-form-group>
        </b-col>
        <b-col xl="6" lg="6" md="6" class="pr-0">
          <!-- label="Syllabus" -->
          <b-form-group
            invalid-feedback="Syllabus is required."
            :ref="'option' + ind"
          >
            <div class="d-flex align-items-center justify-content-between">
              <label class="bv-no-focus-ring col-form-label pt-0"
                >Syllabus</label
              >
              <b-form-checkbox
                v-model="item.isOther"
                switch
                class="ml-2 pb-50"
                v-b-tooltip.hover.top
                title="Urdu"
              />
              <!-- @input="changeAlign(item.isOther, ind)" -->
            </div>
            <b-form-textarea
              placeholder=""
              :id="'option' + ind"
              v-model="item.syllabus"
              :class="{ 'text-right': item.isOther }"
              row="2"
            ></b-form-textarea>
            <!-- <b-form-input
              placeholder=""
              :id="'option' + ind"
              v-model="item.syllabus"
            /> -->
          </b-form-group>
        </b-col>
        <b-col xl="1" lg="1" md="1" class="mt-md-1">
          <b-button
            v-if="ind == syllObj.length - 1"
            @click="AddValue(item, ind + 1)"
            variant="primary"
            class="btn-icon rounded-circle mr-50"
          >
            <feather-icon size="16" icon="PlusIcon" />
          </b-button>
          <b-button
            v-if="syllObj.length > 1"
            @click="removeValue(item, ind)"
            variant="outline-danger"
            class="btn-icon rounded-circle"
          >
            <feather-icon size="16" icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-row align-h="center" class="mt-1">
      <b-col md="6">
        <b-button block variant="success" :disabled="request" @click="save()">
          <b-spinner v-if="request" small type="grow" />
          <span v-else>Save </span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    flatPickr,
    BFormTextarea,
    BImg,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = this.$store.state.rights["manage-datesheet"];
    if (!right) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      if (this.$route.params.id > 0 && right.edit) {
        // this.loadQues();
        this.myObj = this.$route.params.obj.datesheetGrid;
        this.syllObj = this.$route.params.obj.datesheetData;
        this.LoadData();
        this.loadSession();
      } else if (this.$route.params.id == 0 && right.add) {
        this.LoadData();
        this.loadSession();
      } else {
        this.$router.replace({
          name: "misc-not-authorized",
        });
      }
      //   this.loadCat();
      //   this.loadTypes();
      //   this.loadChaps();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return pro.subject.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      isOther: false,
      rights: {},
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Subject",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "Subject", key: "subject" },
        { key: "actions", label: "actions" },
      ],
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },

      items: [],
      classes: [],
      allClasses: [],
      currentSections: [],
      examType: [],
      sessions: [],
      subjects: [],
      currIDs: [],
      syllObj: [
        {
          id: 0,
          date: "",
          day: "",
          subjectID: 0,
          syllabus: "",
          datesheetGridID: 0,
          campusID: this.$store.state.userData.cId,
        },
      ],

      myObj: {
        clsID: 0,
        secID: [],
        sessionID: 0,
        examID: 0,
        isPublished: false,
        publishedFrom: null,
        publishedTo: null,
        campusID: this.$store.state.userData.cId,
      },
      sessionObj: {
        id: 0,
        session: "",
        campusID: this.$store.state.userData.cId,
      },
      typeObj: {
        id: 0,
        questionType: "",
        campusID: this.$store.state.userData.cId,
      },
      quesTypes: [],
      visibility4: false,
      typeLoading: false,
      typeFields: [
        { label: "type", key: "questionType" },
        { key: "actions", label: "actions" },
      ],
      chapObj: {
        id: 0,
        chapter: "",
        subjectID: 0,
        campusID: this.$store.state.userData.cId,
      },
      chapters: [],
      visibility3: false,
      chapLoading: false,
      chFields: [
        { label: "chapter", key: "chapter" },
        { key: "actions", label: "actions" },
      ],
      categories: [],

      topics: [],

      savingSess: false,
      sessFields: [
        { label: "Name", key: "session" },
        { key: "actions", label: "actions" },
      ],

      coverLoading: false,
      expLoading: false,
      catObj: {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      },
      visibility2: false,
      savingCat: false,
      catFields: [
        { label: "level", key: "category" },
        { key: "actions", label: "actions" },
      ],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    changeAlign(state, index) {
      let elem = document.getElementById("option" + index);
      elem.style.textAlign = state ? "right" : "left";
    },

    setDate(item) {
      console.log(item.date);
      // item.day = item.date.split(",")[1];
      let d = new Date(item.date);
      item.day = this.days[d.getUTCDay()];
      console.log(item.day);
    },

    setSec() {
      if (this.myObj.secID.at(-1) == 0) {
        this.myObj.secID = [0];
      } else {
        this.myObj.secID = this.myObj.secID.filter((el) => el != 0);
      }
    },

    FillSections(text) {
      // console.log(this.myObj.clsID);
      this.allClasses.forEach((elem) => {
        if (elem.cls.id === this.myObj.clsID) {
          if (elem.sections.length != 0) {
            this.currentSections = [...elem.sections];
            // this.currentSections = [...elem.sections];
            // this.currentSections.unshift({ section: "All Sections", id: 0 });
          } else {
            // console.log("null");
            this.currentSections = [];
            // this.currentSections = [{ section: "All Sections", id: 0 }];
          }
        }
      });
      if (text != "filled") this.myObj.secID = 0;
      // console.log("Thisis", this.currentSections);
    },

    CheckCatName() {
      var elem = this.$refs["cat_name"];
      if (this.catObj.category.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async AddCategory() {
      this.CheckCatName();
      if (this.CheckCatName() == true) {
        if (this.catObj.id == 0) {
          //Add
          this.savingCat = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizCategory?db=" +
              this.$store.state.userData.db,
            body: this.catObj,
            message: "Quiz Level added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingCat = false;
          if (status) {
            this.loadCat();
            this.catObj = {
              id: 0,
              category: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        } else {
          //Edit
          this.savingCat = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "QuizCategory/" +
              this.catObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.catObj,
            message: "Quiz Level updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingCat = false;
          if (status) {
            this.loadCat();
            this.catObj = {
              id: 0,
              category: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        }
      }
    },
    editCat(item) {
      this.catObj = { ...item };
      this.buttonIcon = "Edit2Icon";
      var elem = this.$refs["cat_name"];
      elem.state = undefined;
    },
    openCat() {
      this.catObj = {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility2 = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["cat_name"];
      elem.state = undefined;
    },
    async DeleteCat(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizCategory/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Quiz Level removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadCat();
      }
    },

    CheckSess() {
      var elem = this.$refs["sess_name"];
      if (this.sessionObj.session.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTSub() {
      var elem = this.$refs["topic_sub"];
      if (this.sessionObj.subjectID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async AddSess() {
      this.CheckSess();
      if (this.CheckSess() == true) {
        if (this.sessionObj.id == 0) {
          //Add
          this.savingSess = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Sessions?db=" +
              this.$store.state.userData.db,
            body: this.sessionObj,
            message: "Session added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingSess = false;
          if (status) {
            this.loadSession();
            this.sessionObj = {
              id: 0,
              topic: "",
              subjectID: 0,
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        } else {
          //Edit
          this.savingSess = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Sessions/" +
              this.sessionObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.sessionObj,
            message: "Session updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingSess = false;
          if (status) {
            this.loadSession();
            this.sessionObj = {
              id: 0,
              topic: "",
              subjectID: 0,
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        }
      }
    },
    editSess(item) {
      this.sessionObj = { ...item };
      this.buttonIcon = "Edit2Icon";
      var elem = this.$refs["sess_name"];
      elem.state = undefined;
    },
    openSession() {
      this.sessionObj = {
        id: 0,
        session: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["sess_name"];
      elem.state = undefined;
    },
    async DeleteSess(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Sessions/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Session removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadSession();
      }
    },
    async setCurrentSess(id) {
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Sessions/SetCurrentSession?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&sessionID=" +
          id,
        body: null,
        message: "Session marked as current",
        context: this,
        token: this.$store.state.userData.token,
      });

      if (status) {
        this.loadSession();
      }
    },
    setSubjects() {
      this.currIDs = this.syllObj.reduce((acc, el) => {
        acc.push(el.subjectID);
        return acc;
      }, []);
    },
    AddValue(item, ind) {
      if (item.syllabus.trim() !== "") {
        this.syllObj.push({
          id: 0,
          date: "",
          day: "",
          subjectID: 0,
          syllabus: "",
          datesheetGridID: 0,
          campusID: this.$store.state.userData.cId,
        });
        setTimeout(() => {
          let elem = document.getElementById("option" + ind);
          elem.focus();
          elem.select();
        }, 100);
      }
    },
    async removeValue(item, ind) {
      if (item.id == 0) {
        this.syllObj.splice(ind, 1);
        // console.log(this.syllObj);
        this.setSubjects();
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "DatesheetData/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.syllObj.splice(ind, 1);
          this.setSubjects();
        }
        // console.log(this.syllObj);
      }
    },
    checkCls() {
      var elem = this.$refs["class"];
      if (this.myObj.clsID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkSec() {
      var elem = this.$refs["sec"];
      if (this.myObj.secID.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkExam() {
      var elem = this.$refs["examType"];
      if (this.myObj.examID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkSessID() {
      var elem = this.$refs["session"];
      if (this.myObj.sessionID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckValues() {
      let state = true;
      this.syllObj.forEach((el) => {
        if (el.date !== "") {
          el.date = el.date.split(",")[0];
        }
        if (el.date == "" || el.subjectID == 0 || el.syllabus.trim() == "") {
          state = false;
        }
      });
      return state;
    },
    async save() {
      this.checkCls();
      this.checkSec();
      this.checkExam();
      this.checkSessID();
      if (
        this.checkCls() == false ||
        this.checkSec() == false ||
        this.checkExam() == false ||
        this.checkSessID() == false
      ) {
        this.$bvToast.toast("Enter the details correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (this.CheckValues() == false) {
        this.$bvToast.toast("Please enter syllabus details correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        let saveObj = {
          datesheetGrid: this.myObj,
          datesheetData: this.syllObj,
        };
        console.log(saveObj);

        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "DatesheetGrid/Save?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: saveObj,
          message: "Datesheet saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.$router.push("/manage-datesheet");
        }
      }
    },
    deleteCover() {
      this.myObj.question.media = null;
      this.coverLoading = false;
      this.$refs.coverImg.value = null;
    },
    selectCover() {
      // https://cdn.cloudious.net/file-1683207466927-684850391.png
      if (this.$refs.coverImg.files[0] !== "") {
        this.coverLoading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.coverImg.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.myObj.question.media = fn1;
            this.coverLoading = "loaded";
          })
          .catch((err) => {
            console.log(err);
            this.coverLoading = false;
          });
      }
    },
    deleteExpl() {
      this.myObj.question.explanationMedia = null;
      this.expLoading = false;
      this.$refs.expImg.value = null;
    },
    selectExpl() {
      if (this.$refs.expImg.files[0] !== "") {
        this.expLoading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.expImg.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.myObj.question.explanationMedia = fn1;
            this.expLoading = "loaded";
          })
          .catch((err) => {
            console.log(err);
            this.expLoading = false;
          });
      }
    },
    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("editObj:", this.myObj);
      this.adding = false;
      this.visibility = true;
      this.sidebarTitle = "Edit Subject";
      this.sidebarButton = "Update";
      var elem = this.$refs["subject"];
      elem.state = undefined;
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj2 = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.allClasses = await this.get(obj2);
      this.allClasses.forEach((el) => this.classes.push(el.cls));
      if (this.$route.params.id > 0) this.FillSections("filled");

      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.subjects = await this.get(obj);
      if (this.$route.params.id > 0) this.setSubjects();

      var obj3 = {
        url:
          this.$store.state.domain +
          "ExamTypes?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.examType = await this.get(obj3);

      this.dataLoading = false;
      // console.log("subject", this.items);
    },

    async loadQues() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizQuestions/LoadQuestion?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&qID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };

      this.myObj = await this.get(obj);
      this.syllObj = this.myObj.syllabus;
    },

    async loadCat() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizCategory?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.categories = await this.get(obj);

      // console.log("categories", this.categories);
    },
    async loadSession() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };

      this.sessions = await this.get(obj);
      // console.log( this.sessions);
    },

    async loadChaps() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizChapters?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.chapters = await this.get(obj);

      // console.log("chapters", this.chapters);
    },

    openChap() {
      this.chapObj = {
        id: 0,
        chapter: "",
        subjectID: 0,
        campusID: this.$store.state.userData.cId,
      };

      this.visibility3 = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["chapter"];
      elem.state = undefined;
      var elem = this.$refs["ch_sub"];
      elem.state = undefined;
    },

    editChap(item) {
      this.chapObj = { ...item };
      this.buttonIcon = "Edit2Icon";

      var elem = this.$refs["chapter"];
      elem.state = undefined;
      var elem = this.$refs["ch_sub"];
      elem.state = undefined;
    },

    checkChap() {
      var elem = this.$refs["chapter"];
      if (this.chapObj.chapter.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckChSub() {
      var elem = this.$refs["ch_sub"];
      if (this.chapObj.subjectID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async AddChap() {
      this.checkChap();
      this.CheckChSub();
      if (this.checkChap() == true && this.CheckChSub() == true) {
        if (this.chapObj.id == 0) {
          //Add
          this.chapLoading = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizChapters?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: this.chapObj,
            message: "Chapter added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.chapLoading = false;
          if (status) {
            this.loadChaps();
            this.chapObj = {
              id: 0,
              chapter: "",
              subjectID: 0,
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        } else {
          //Edit
          this.chapLoading = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "QuizChapters/" +
              this.chapObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.chapObj,
            message: "Chapter updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.chapLoading = false;
          if (status) {
            this.loadChaps();
            this.chapObj = {
              id: 0,
              chapter: "",
              subjectID: 0,
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        }
      }
    },

    async deleteChap(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizChapters/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Chapter removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadChaps();
      }
    },

    async loadTypes() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizQuestionType?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.quesTypes = await this.get(obj);

      // console.log("quesTypes", this.quesTypes);
    },

    openType() {
      this.typeObj = {
        id: 0,
        questionType: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility4 = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["q_type"];
      elem.state = undefined;
    },

    editType(item) {
      this.typeObj = { ...item };
      this.buttonIcon = "Edit2Icon";

      var elem = this.$refs["q_type"];
      elem.state = undefined;
    },

    checkType() {
      var elem = this.$refs["q_type"];
      if (this.typeObj.questionType.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    async AddType() {
      if (this.checkType() == true) {
        if (this.typeObj.id == 0) {
          //Add
          this.typeLoading = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizQuestionType?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: this.typeObj,
            message: "Quiz Type added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.typeLoading = false;
          if (status) {
            this.loadTypes();
            this.typeObj = {
              id: 0,
              questionType: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        } else {
          //Edit
          this.typeLoading = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "QuizQuestionType/" +
              this.typeObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.typeObj,
            message: "Quiz Type updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.typeLoading = false;
          if (status) {
            this.loadTypes();
            this.typeObj = {
              id: 0,
              questionType: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        }
      }
    },

    async deleteType(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizQuestionType/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Quiz Type removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadTypes();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
